<template>
  <div>
    <v-dialog persistent v-model="countDialog" fullscreen>
      <v-card>
        <v-toolbar
            dark
            color="#00004d"
        >
          <v-btn
              icon
              dark
              @click="countDialog = false;countDialogModule='';countDialogModuleTitle=''"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!--          <v-toolbar-title>-->
          <!--            <span v-html="editedItem.full_name + '\'s Properties'"></span>-->
          <!--          </v-toolbar-title>-->
        </v-toolbar>
        <DashboardViewAllCount :module="countDialogModule" :module-title="countDialogModuleTitle"/>
      </v-card>
    </v-dialog>
    <div class="row">
      <div class="col-lg-4 col-sm-4">
        <div class="bg-light-success text-center pt-3 pb-3">
          <p class="font-weight-bold font-size-h3 mb-3">
            Total Listings
          </p>
          <p class="font-weight-bold font-size-h6 m-0">
            {{ totalListings }}
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-4">
        <div class="bg-light-success text-center pt-3 pb-3">
          <p class="font-weight-bold font-size-h3 mb-3">
            Live Listings
          </p>
          <p class="font-weight-bold font-size-h6 m-0">
            {{ liveListings }}
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-4">
        <div class="bg-light-success text-center pt-3 pb-3">
          <p class="font-weight-bold font-size-h3 mb-3">
            Assigned Listings
          </p>
          <p class="font-weight-bold font-size-h6 m-0">
            {{ assignedListings }}
          </p>
        </div>
      </div>
      <div class="col col-12 pt-0 pb-0" v-if="viewFull === 0">
        <div class="text-right">
          <v-btn color="#00004d" dark @click="$router.push({path: '/listings-dashboard'})">Explore Listings Dashboard
          </v-btn>
        </div>
      </div>
    </div>
    <div v-if="viewFull === 1">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
            <div class="text-center font-weight-bold">
              Search/Filters
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form @submit.stop.prevent="hitAdvanceSearch(false)">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                      dense outlined
                      hide-details
                      clearable
                      @change="drChange"
                      v-model="dateRange"
                      :items="dateRangeFilter"
                      item-text="title"
                      item-value="value"
                      label="Date Range"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field type="date" dense outlined hide-details v-model="fromDateSearch"
                                label="From Date"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field type="date" dense outlined hide-details v-model="toDateSearch"
                                label="To Date"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <button :disabled="loading" type="submit" style="width: 100%"
                          class="btn btn-success theme-btn ml-auto mr-2">
                    Search
                    <v-icon class="ml-2" small>fas fa-search</v-icon>
                  </button>
                </v-col>
                <v-col cols="12" sm="6">
                  <button :disabled="loading" style="width: 100%" @click="hitAdvanceSearch(true)"
                          class="btn btn-primary ml-auto">
                    Reset
                    <v-icon class="ml-2" small>fas fa-times</v-icon>
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Listings Trends Daily
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="ltdGraph" type="line" height="350" :options="chartOptions1" :series="series1"></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Listings By Source
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="srcGraph" type="line" height="350" :options="chartOptions2" :series="series2"></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Listings By Purpose
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="purposeGraph" type="line" height="350" :options="chartOptions2"
                       :series="series2"></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Listings By Developments
            <v-spacer></v-spacer>
            <v-btn color="#00004d" dark
                   @click="countDialog=true;countDialogModule='Listings';countDialogModuleTitle='Developments'">
              View All
            </v-btn>
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="devGraph" type="line" height="350" :options="chartOptions2" :series="series2"></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Listings By Projects
            <v-spacer></v-spacer>
            <v-btn color="#00004d" dark
                   @click="countDialog=true;countDialogModule='Listings';countDialogModuleTitle='Projects'">
              View All
            </v-btn>
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="proGraph" type="line" height="350" :options="chartOptions2" :series="series2"></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Listings By Bedroom
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="bedGraph" type="line" height="350" :options="chartOptions2" :series="series2"></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Listings By Status
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="statusGraph" type="line" height="350" :options="chartOptions2"
                       :series="series2"></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Listings By Property Type
            <v-spacer></v-spacer>
            <v-btn color="#00004d" dark
                   @click="countDialog=true;countDialogModule='Listings';countDialogModuleTitle='Status'">
              View All
            </v-btn>
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="ptGraph" type="line" height="350" :options="chartOptions2" :series="series2"></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Listings By Completion Status
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="csGraph" type="line" height="350" :options="chartOptions2" :series="series2"></apexchart>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-0"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Listings By Agents
            <v-spacer></v-spacer>
            <v-btn color="#00004d" dark
                   @click="countDialog=true;countDialogModule='Listings';countDialogModuleTitle='Agents'">
              View All
            </v-btn>
          </v-card-title>
          <div class="bg-white">
            <apexchart ref="userGraph" type="line" height="350" :options="chartOptions2" :series="series2"></apexchart>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import apexchart from "vue-apexcharts";
import DashboardViewAllCount from "@/view/pages/realstate/dashboard/inc/DashboardViewAllCount";
import {LISTINGS_DASHBOARD_STATS} from "@/core/services/store/listings.module";

export default {
  name: "listings-dashboard-stats",
  props: ['viewFull'],
  components: {
    DashboardViewAllCount,
    apexchart,
  },
  data() {
    return {
      totalListings: '0',
      liveListings: '0',
      assignedListings: '0',
      dateRange: '',
      fromDateSearch: this.$store.state.auth.c_d,
      toDateSearch: this.$store.state.auth.c_d,
      loading: false,
      series1: [
        {
          name: 'Listings',
          type: 'column',
          data: []
        }
      ],
      chartOptions1: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [4]
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0]
        },
        xaxis: {},
        yaxis: [
          {
            title: {
              text: '',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          }]
      },
      series2: [
        {
          name: 'No. Of Listings',
          type: 'column',
          data: []
        }
      ],
      chartOptions2: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [1]
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0]
        },
        xaxis: {},
        yaxis: [
          {
            title: {
              text: '',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('en-US');
              }
            }
          }]
      },
    };
  },
  watch: {},
  computed: {
    ...mapState({
      errors: state => state.listings.errors
    })
  },
  mounted() {
    this.getStats()
  },
  methods: {
    getStats() {
      this.loading = true
      this.$store.dispatch(LISTINGS_DASHBOARD_STATS, {
        fromDateSearch: this.fromDateSearch,
        toDateSearch: this.toDateSearch,
        viewFull: this.viewFull
      }).then((res) => {
        this.totalListings = res.records.mainStats.total_listings
        this.liveListings = res.records.mainStats.live_listings
        this.assignedListings = res.records.mainStats.assigned_listings
        if (this.viewFull === 0) {
          this.$emit('tDevsPros', res.records.tDevs, res.records.tPros)
        }
        if (this.viewFull === 1) {
          this.$refs.ltdGraph.updateOptions(
              {xaxis: {categories: res.records.ltdGraph.date}}
          )
          this.$refs.ltdGraph.updateSeries([
            {data: res.records.ltdGraph.count},
          ])
          this.$refs.srcGraph.updateOptions(
              {xaxis: {categories: res.records.srcGraph.title}}
          )
          this.$refs.srcGraph.updateSeries([
            {data: res.records.srcGraph.count},
          ])
          this.$refs.purposeGraph.updateOptions(
              {xaxis: {categories: res.records.purposeGraph.title}}
          )
          this.$refs.purposeGraph.updateSeries([
            {data: res.records.purposeGraph.count},
          ])
          this.$refs.devGraph.updateOptions(
              {xaxis: {categories: res.records.devGraph.title}}
          )
          this.$refs.devGraph.updateSeries([
            {data: res.records.devGraph.count},
          ])
          this.$refs.proGraph.updateOptions(
              {xaxis: {categories: res.records.proGraph.title}}
          )
          this.$refs.proGraph.updateSeries([
            {data: res.records.proGraph.count},
          ])
          this.$refs.bedGraph.updateOptions(
              {xaxis: {categories: res.records.bedGraph.title}}
          )
          this.$refs.bedGraph.updateSeries([
            {data: res.records.bedGraph.count},
          ])
          this.$refs.userGraph.updateOptions(
              {xaxis: {categories: res.records.userGraph.title}}
          )
          this.$refs.userGraph.updateSeries([
            {data: res.records.userGraph.count},
          ])
          this.$refs.statusGraph.updateOptions(
              {xaxis: {categories: res.records.statusGraph.title}}
          )
          this.$refs.statusGraph.updateSeries([
            {data: res.records.statusGraph.count},
          ])
          this.$refs.csGraph.updateOptions(
              {xaxis: {categories: res.records.csGraph.title}}
          )
          this.$refs.csGraph.updateSeries([
            {data: res.records.csGraph.count},
          ])
          this.$refs.ptGraph.updateOptions(
              {xaxis: {categories: res.records.ptGraph.title}}
          )
          this.$refs.ptGraph.updateSeries([
            {data: res.records.ptGraph.count},
          ])
        }
        this.loading = false
      });
    },
    drChange(v) {
      if (v) {
        if (v === "1") {
          let date = new Date();
          let cdate = new Date();
          let lastmonths = new Date(cdate.setMonth(cdate.getMonth() - 1));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = lastmonths.getFullYear() + '-' + ('0' + (lastmonths.getMonth() + 1)).slice(-2) + '-' + ('0' + lastmonths.getDate()).slice(-2);
          this.toDateSearch = toDate
          this.fromDateSearch = fromDate
        } else if (v === "2") {
          let date = new Date();
          let cdate = new Date();
          let last3months = new Date(cdate.setMonth(cdate.getMonth() - 2));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last3months.getFullYear() + '-' + ('0' + (last3months.getMonth())).slice(-2) + '-' + ('0' + last3months.getDate()).slice(-2);
          this.toDateSearch = toDate
          this.fromDateSearch = fromDate
        } else if (v === "3") {
          let date = new Date();
          let cdate = new Date();
          let last6months = new Date(cdate.setMonth(cdate.getMonth() - 5));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last6months.getFullYear() + '-' + ('0' + (last6months.getMonth())).slice(-2) + '-' + ('0' + last6months.getDate()).slice(-2);
          this.toDateSearch = toDate
          this.fromDateSearch = fromDate
        } else if (v === "4") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = (date.getFullYear() - 1) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          this.toDateSearch = toDate
          this.fromDateSearch = fromDate
        } else if (v === "5") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = date.getFullYear() + '-01-01';
          this.toDateSearch = toDate
          this.fromDateSearch = fromDate
        }
      } else {
        this.toDateSearch = this.$store.state.auth.c_d
        this.fromDateSearch = this.$store.state.auth.c_d
      }
    },
    hitAdvanceSearch(reset = false) {
      if (reset) {
        this.dateRange = ''
        this.toDateSearch = this.$store.state.auth.c_d
        this.fromDateSearch = this.$store.state.auth.c_d
      }
      this.getStats();
    }
  }
};
</script>
