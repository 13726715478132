<template>
  <div>
    <!--begin::Dashboard-->
    <v-row>
      <v-col cols="12" sm="12">
        <ListingsDashboardStats :viewFull="1" />
      </v-col>
    </v-row>
  </div>
  <!--end::Dashboard-->
</template>

<script>
import ListingsDashboardStats from "@/view/pages/realstate/dashboard/listings-inc/ListingsDashboardStats";

export default {
  name: "leads-dashboard",
  components: {ListingsDashboardStats},
  mounted() {

  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
